<script setup>
import { onMounted, ref } from 'vue';
import FormAsset from '../../components/FormAsset.vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()
const formAsset = ref(null)

const detail = ref({
  priceBid: 0,
  priceLand: 0,
  areaSurface: 0,
  areaBuilding: 0,
  addressId: null,
  contactSourceId: null,
  kjppProperty: {
    contactSource: {
      name: '',
      phoneNumber: ''
    },
    address: {
      name: '',
      subDistrict: '',
      district: '',
      region: '',
      state: ''
    }
  }
})

onMounted(async () => {
})

async function onCreateAsset(model) {
  const files = formAsset.value.uploader.acceptedFiles
  await axios.post(`/api/assets/`, model).then((response) => {
    const formData = new FormData(); // pass data as a form
    for (var x = 0; x < files.length; x++) {
      // append files as array to the form, feel free to change the array name
      // formData.append("images[]", files[x]);
      formData.append("file", files[x]);
    }

    // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.

    return axios
      .post(`/api/assets/${response.data?.id}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.info(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }).then(() => {
    router.go(-1)
  })
}
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">{{ $t('assets.create') }}</h5>
      </div>
    </div>

    <FormAsset v-model="detail" ref="formAsset" @update="onCreateAsset" />
  </div>
</template>