import { defineStore } from "pinia"

export const useAnalyticsStore = defineStore('analytics', {
  state: () => ({
    analytics: null,
    rates: null,
  }),
  getters: {
    getAnalytics: (state) => state.analytics,
    getRates: (state) => state.rates,
  },
  actions: {
    setAnalytics(obj) {
      this.analytics = obj
    },
    setRates(obj) {
      this.rates = obj
    }
  },
})