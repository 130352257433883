<script setup>
import { onMounted, ref, watch } from 'vue'
import CardGoogleMaps from './CardGoogleMaps.vue'
import axios from 'axios'
import InputSelector from './InputSelector.vue';
import FormContact from './FormContact.vue';
import FormAddress from './FormAddress.vue';
import VueDatePicker from '@vuepic/vue-datepicker'
import VueMultiselect from 'vue-multiselect'
import Uploader from './Uploader.vue';

const contacts = ref([])
const addresses = ref([])
const assetTypes = ref([])
const purposes = ref([])
const isLoading = ref(true)
const selectedPropertyType = ref(null)
const selectedContactSource = ref(null)
const selectedAddress = ref(null)
const selectedPropertyPurpose = ref(null)
const uploader = ref(null)

defineExpose({
  uploader
})

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  permits: {
    type: Array,
    default: () => []
  }
})

const editorVisibilities = ref({
  contact: false,
  address: false
})
const emit = defineEmits(['update'])

function fetchContacts() {
  const contactsData = axios.get('/api/contacts').then(response => {
    contacts.value = response.data
    selectedContactSource.value = contacts.value.find(x => x.id === props.modelValue.kjppProperty.contactSourceId)
  })

  return contactsData
}

function fetchAddresses() {
  const addressesData = axios.get('/api/addresses').then(response => {
    addresses.value = response.data
    selectedAddress.value = addresses.value.find(x => x.id === props.modelValue.kjppProperty.addressId)
  })
  return addressesData
}

function fetchTypes() {
  const assetTypesData = axios.get('/api/assets/types').then(response => {
    assetTypes.value = response.data
    selectedPropertyType.value = assetTypes.value.find(x => x.id === props.modelValue.kjppProperty.kjppPropertyTypeId)
  })
  return assetTypesData
}

function fetchPropertyPurposes() {
  const purposesData = axios.get('/api/assets/purposes').then(response => {
    purposes.value = response.data
    selectedPropertyPurpose.value = purposes.value.find(x => x.id === props.modelValue.propertyPurposeId)
  })
  return purposesData
}

onMounted(async () => {
  isLoading.value = true
  const lsit = await Promise.all([fetchContacts(), fetchAddresses(), fetchTypes(), fetchPropertyPurposes()])
  isLoading.value = false
})

function onUpdateEvent() {
  emit('update', props.modelValue)
}

function toggleEditor(key) {
  editorVisibilities.value[key] = !editorVisibilities.value[key]
}

function formatCurrency(amount) {
  let IDRupiah = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })

  return IDRupiah.format(amount)
}

function formatArea(area) {
  return `${area} m²`
}

function onSelectPropertyType(value) {
  props.modelValue.kjppProperty.kjppPropertyTypeId = value.id
}

function customContactSourceDisplay({ name, phoneNumber }) {
  return `${name} - ${phoneNumber}`
}

function onSelectAddress(value) {
  props.modelValue.kjppProperty.addressId = value.id
}

function onSelectContactSource(value) {
  props.modelValue.kjppProperty.contactSourceId = value.id
}

function onSelectPropertyPurpose(value) {
  props.modelValue.propertyPurposeId = value.id
}
</script>

<template>
  <div class="card rounded shadow">
    <div class="p-4 border-bottom">
      <h5 class="title mb-0"> {{ $t('assets.manage') }} </h5>
    </div>

    <div class="p-4">
      <form v-if="modelValue" @submit.prevent="onUpdateEvent">
        <div class="row" v-if="modelValue.locationLatitude && modelValue.locationLongitude">
          <div class="col-sm-12 mb-3">
            <CardGoogleMaps :center="{ lat: modelValue.locationLatitude, lng: modelValue.locationLongitude }" :zoom="14" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-if="modelValue.kjppProperty">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.property_type') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <VueMultiselect
                  v-model="selectedPropertyType"
                  :options="assetTypes"
                  label="name"
                  value="id"
                  track-by="id"
                  :disabled="disabled"
                  @select="onSelectPropertyType"
                  >
                </VueMultiselect>
                <!-- <InputSelector :disabled="disabled" aria-label="Contacts" v-model="modelValue.kjppProperty.kjppPropertyTypeId" :options="assetTypes" :placeholder="$t('assets.choose_property_type')" label-prop="name" value-prop="id" /> -->
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.property_purpose') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <VueMultiselect
                  v-model="selectedPropertyPurpose"
                  :options="purposes"
                  label="name"
                  value="id"
                  track-by="id"
                  :disabled="disabled"
                  @select="onSelectPropertyPurpose"
                  >
                </VueMultiselect>
                <!-- <InputSelector :disabled="disabled" aria-label="Contacts" v-model="modelValue.kjppProperty.kjppPropertyTypeId" :options="assetTypes" :placeholder="$t('assets.choose_property_type')" label-prop="name" value-prop="id" /> -->
              </div>
            </div>
          </div>
          <div v-if="modelValue.kjppProperty" class="col-md-6">
            <div class="mb-3">
              <div class="d-flex justify-content-between align-items-end">
                <div class="w-100">
                  <label class="form-label">{{ $t('assets.date_verified') }} <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <VueDatePicker v-model="modelValue.kjppProperty.dateVerified" :disabled="disabled" name="dateVerified" id="date-verified"></VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.price_bid') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <input v-model.number="modelValue.priceBid" name="priceBid" id="price-bid" type="text" class="form-control" placeholder="Price Bid :" :disabled="disabled" >
              </div>
              <small id="priceBidHelp" class="form-text text-muted">{{ formatCurrency(modelValue.priceBid) }}</small>
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.price_land') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <input v-model.number="modelValue.priceLand" name="priceLand" id="price-land" type="text" class="form-control" placeholder="Price Land :" :disabled="disabled">
              </div>
              <small id="priceLandHelp" class="form-text text-muted">{{ formatCurrency(modelValue.priceLand) }}</small>
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.area_surface') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <vue-feather class="icons" type="square" size="16"></vue-feather>
                <input v-model.number="modelValue.areaSurface" name="areaSurface" id="area-surface" type="text" class="form-control ps-5" placeholder="Surface Area :" :disabled="disabled">
              </div>
              <small id="areaSurfaceHelp" class="form-text text-muted">{{ formatArea(modelValue.areaSurface) }}</small>
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.area_building') }} <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <vue-feather class="icons" type="square" size="16"></vue-feather>
                <input v-model.number="modelValue.areaBuilding" name="areaBuilding" id="area-building" type="text" class="form-control ps-5" placeholder="Building Area :" :disabled="disabled">
              </div>
              <small id="areaBuildingHelp" class="form-text text-muted">{{ formatArea(modelValue.areaBuilding) }}</small>
            </div>
          </div><!--end col-->
          <div class="col-md-6">
            <div class="mb-3">
              <div class="d-flex justify-content-between align-items-end">
                <div class="m-2 w-100">
                  <label class="form-label">{{ $t('contacts.title') }} <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <VueMultiselect
                      v-model="selectedContactSource"
                      :options="contacts"
                      value="id"
                      track-by="id"
                      :custom-label="customContactSourceDisplay"
                      :disabled="disabled"
                      @select="onSelectContactSource"
                      >
                    </VueMultiselect>
                    <!-- <InputSelector v-if="modelValue.kjppProperty" :disabled="disabled" aria-label="Contacts" v-model="modelValue.kjppProperty.contactSourceId" :options="contacts" placeholder="Choose contact" label-prop="name,phoneNumber" value-prop="id" /> -->
                  </div>
                </div>
                <div v-if="!disabled && modelValue.kjppProperty">
                  <button class="btn btn-icon btn-pills btn-outline-primary mb-2" type="button" @click="toggleEditor('contact')">
                    <vue-feather type="edit" size="16"></vue-feather>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!disabled" class="col-md-6">
            <div class="mb-3">
              <div class="d-flex justify-content-between align-items-end">
                <div class="m-2 w-100">
                  <label class="form-label">{{ $t('addresses.title') }} <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <VueMultiselect
                      v-model="selectedAddress"
                      :options="addresses"
                      label="name"
                      value="id"
                      track-by="id"
                      :disabled="disabled"
                      @select="onSelectAddress"
                      >
                    </VueMultiselect>
                    <!-- <InputSelector v-if="modelValue.kjppProperty" :disabled="disabled" aria-label="Address" v-model="modelValue.kjppProperty.addressId" :options="addresses" placeholder="Choose address" label-prop="name" value-prop="id" /> -->
                  </div>
                </div>
                <div v-if="!disabled && modelValue.kjppProperty">
                  <button class="btn btn-icon btn-pills btn-outline-primary mb-2" type="button" @click="toggleEditor('address')">
                    <vue-feather type="edit" size="16"></vue-feather>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <Uploader ref="uploader" v-if="!disabled" class="mb-3" :url="`/api/assets/${modelValue.kjppPropertyId}/upload`" :direct-upload="!disabled && !!modelValue.id" :file-uploaded-callback="() => $router.go($router.currentRoute)" />
            <img v-if="modelValue.propertyImageUrl" :src="`/api/media/${modelValue.kjppPropertyId}`" />
          </div>
        </div><!--end row-->
      </form><!--end form-->
        
      <div class="row">
        <div v-if="editorVisibilities.contact" class="col-md-12">
          <FormContact v-model="modelValue.kjppProperty.contactSource" :disabled="disabled" />
        </div>
        <div class="col-md-12">
          <FormAddress v-if="modelValue.kjppProperty.address" v-model="modelValue.kjppProperty.address" :disabled="disabled" />
        </div>
        <div class="col-sm-12">
          <div class="d-flex justify-content-between">
            <div>
              <button type="button" :class="['btn', { 'btn-primary': disabled, 'btn-outline-primary': !disabled }]" @click="$router.go(-1)">{{ $t('general.back') }}</button>
            </div>

            <div>
              <router-link v-if="disabled && permits.includes('allow-update')" :to="`/assets/update/${modelValue?.kjppProperty.id}`" class="btn btn-outline-primary">{{ $t('general.edit') }}</router-link>
              <input v-else-if="permits.includes('allow-update')" type="submit" id="submit" name="save" class="btn btn-primary" :value="$t('general.save')" @click="onUpdateEvent">
            </div>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div>
  </div>
</template>

<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->
<style lang="scss" scoped>
.form-select {
  padding-left: 2.75rem;
}

.dp__disabled {
  background: #dee2e6 !important;
  border: 1px solid #e9ecef;
}

.dp__theme_light {
  --dp-disabled-color: #dee2e6;
}

</style>