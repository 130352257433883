<script setup>
import { ref } from 'vue'
const props = defineProps({
  text: String,
  to: [Object, String],
  icon: String,
  submenus: {
    type: Array,
    default: () => []
  },
  permits: {
    type: Array,
    default: () => []
  },
  requiredPerm: String
})

const isActive = ref(false)
</script>

<template>
  <component v-if="!requiredPerm || (requiredPerm && permits.includes(requiredPerm))" is="li" :class="['sidebar-dropdown', { 'active': isActive }]">
    <router-link :to="to" @click="() => isActive = !isActive"><i :class="`ti ti-${icon} me-2`"></i>{{ text }}</router-link>
    <div :class="['sidebar-submenu', { 'd-block': isActive }]">
      <ul>
        <component v-for="(submenu, submenuKey) in submenus" :key="submenuKey" is="li">
          <router-link :to="submenu.to">
            {{ submenu.text }}
            <span v-if="submenu.badge" :class="['badge', `text-bg-${submenu.badge.variant}`]">{{ submenu.badge.text }}</span>
          </router-link>
        </component>
      </ul>
    </div>
  </component>
</template>