import { defineStore } from "pinia"

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [],
  }),
  getters: {
    getNotifications: (state) => state.notifications,
    getTotalUnread: (state) => state.notifications.filter(n => !n.read).length,
  },
  actions: {
    setNotifications(notifications) {
      notifications.forEach(notif => {
        this.notifications.push(notif)
      })
    },
    addNotification(notification) {
      this.notifications.unshift(notification)
    },
    setRead(id) {
      const notif = this.notifications.find(n => n.id === id)
      if (!notif) {
        return
      }

      notif.read = true
    }
  },
})