<script setup>
import { useAuthStore } from '../../stores/auth';

const authStore = useAuthStore()

function onUnlock() {
  authStore.getUser().then(() => {
    window.location = '/'
  })
}
</script>

<template>
  <section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-8">
          <img src="/assets/images/logo-dark.png" height="24" class="mx-auto d-block" alt="">
          <div class="card login-page shadow mt-4 rounded border-0">
            <div class="card-body">
              <h4 class="text-center">Lock screen</h4>
              <form class="login-form mt-4" @submit.prevent="onUnlock">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3 text-center">
                      <img src="/assets/images/client/05.jpg"
                        class="avatar avatar-md-md rounded-pill mx-auto d-block shadow" alt="">

                      <div class="mt-3">
                        <h5 class="mb-1">Cristina Julia</h5>
                        <p class="text-muted mb-0">UI / UX Designer</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Password <span class="text-danger">*</span></label>
                      <input type="password" class="form-control" placeholder="Password" required="">
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                      <div class="mb-3">
                        <div class="form-check">
                          <input class="form-check-input align-middle" type="checkbox" value="" id="remember-check">
                          <label class="form-check-label" for="remember-check">Remember me</label>
                        </div>
                      </div>
                      <router-link to="/auth/reset-password" class="text-dark h6 mb-0">Forgot password ?</router-link>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-0">
                    <div class="d-grid">
                      <button class="btn btn-primary">Login</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div><!---->
        </div> <!--end col-->
      </div><!--end row-->
    </div> <!--end container-->
  </section>
</template>