<script setup>
import { onMounted, ref } from 'vue';
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'
import { normalizeStyle } from 'vue';
import { useAdministrativeStore } from '../stores/administrative';

const administrativeStore = useAdministrativeStore()

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  noAddress: Boolean,
  allowSelectAll: Boolean,
  disabled: Boolean,
  onlyAddress: Boolean
})

const selectedState = ref(null)
const selectedRegion = ref(null)
const selectedDistrict = ref(null)
const selectedSubDistrict = ref(null)
const selectedAddress = ref(null)
const addressQuery = ref(null)

const states = ref([])
const regions = ref([])
const districts = ref([])
const subDistricts = ref([])
const addresses = ref([])

async function onSelectState(value) {
  if (value) {
    props.modelValue.state = value.name
    props.modelValue.stateId = value.id

    await fetchRegions()
  } else {
    props.modelValue.state = null
    props.modelValue.stateId = null
  
    selectedRegion.value = null
    selectedDistrict.value = null
    selectedSubDistrict.value = null
    selectedAddress.value = null
  }
    
  // props.modelValue.region = null
  // props.modelValue.regionId = null
  
  // props.modelValue.district = null
  // props.modelValue.districtId = null
  
  // props.modelValue.subDistrict = null
  // props.modelValue.subDistrictId = null
  
  // props.modelValue.name = null
  // props.modelValue.addressId = null
}

async function onSelectRegion(value) {
  if (value) {
    props.modelValue.region = value.name
    props.modelValue.regionId = value.id

    await fetchDistricts()
  } else {
    props.modelValue.region = null
    props.modelValue.regionId = null

    selectedDistrict.value = null
    selectedSubDistrict.value = null
    selectedAddress.value = null
  }
    
  // props.modelValue.district = null
  // props.modelValue.districtId = null
  
  // props.modelValue.subDistrict = null
  // props.modelValue.subDistrictId = null
  
  // props.modelValue.name = null
  // props.modelValue.addressId = null
}

async function onSelectDistrict(value) {
  if (value) {
    props.modelValue.district = value.name
    props.modelValue.districtId = value.id

    await fetchSubDistricts()
  } else {
    props.modelValue.district = null
    props.modelValue.districtId = null
  
    selectedSubDistrict.value = null
    selectedAddress.value = null
  }
    
  // props.modelValue.subDistrict = null
  // props.modelValue.subDistrictId = null
  
  // props.modelValue.name = null
  // props.modelValue.addressId = null
}

async function onSelectSubDistrict(value) {
  if(value) {
    props.modelValue.subDistrict = value.name
    props.modelValue.subDistrictId = value.id

    await fetchAddresses()
  } else {
    props.modelValue.subDistrict = null
    props.modelValue.subDistrictId = null
  
    selectedAddress.value = null
  }
}

function onSelectAddress(value) {
  if (value) {
    props.modelValue.name = value.name
    props.modelValue.addressId = value.id
  } else {
    props.modelValue.name = null
    props.modelValue.addressId = null
  }
  administrativeStore.setSelectedAddress(value, addressQuery.value)
}

function fetchStates() {
  const contactsData = axios.get('/api/addresses/provinces').then(response => {
    states.value = response.data
    selectedState.value = states.value.find(x => parseInt(x.id) === props.modelValue.stateId)
    if (selectedState.value) {
      onSelectState(selectedState.value)
    } else {
      selectedRegion.value = null
      selectedDistrict.value = null
      selectedSubDistrict.value = null
      selectedAddress.value = null
    }
  })

  return contactsData
}

function fetchRegions() {
  const contactsData = axios.get(`/api/addresses/regions/${selectedState.value.id}`).then(response => {
    regions.value = response.data
    selectedRegion.value = regions.value.find(x => parseInt(x.id) === props.modelValue.regionId)
    if (selectedRegion.value) {
      onSelectRegion(selectedRegion.value)
    } else {
      selectedDistrict.value = null
      selectedSubDistrict.value = null
      selectedAddress.value = null
    }
  })

  return contactsData
}

function fetchDistricts() {
  const contactsData = axios.get(`/api/addresses/districts/${selectedRegion.value.id}`).then(response => {
    districts.value = response.data
    selectedDistrict.value = districts.value.find(x => parseInt(x.id) === props.modelValue.districtId)

    if (selectedDistrict.value) {
      onSelectDistrict(selectedDistrict.value)
    } else {
      selectedSubDistrict.value = null
      selectedAddress.value = null
    }
  })

  return contactsData
}

function fetchSubDistricts() {
  const contactsData = axios.get(`/api/addresses/subdistricts/${selectedDistrict.value.id}`).then(response => {
    subDistricts.value = response.data
    selectedSubDistrict.value = subDistricts.value.find(x => parseInt(x.id) === props.modelValue.subDistrictId)

    if (selectedSubDistrict.value) {
      onSelectSubDistrict(selectedSubDistrict.value)
    } else {
      selectedAddress.value = null
    }
  })

  return contactsData
}

async function fetchAddresses(query) {
  if (selectedSubDistrict.value) {
    const contactsData = axios.get(`/api/addresses/addresses/${selectedSubDistrict.value.id}`).then(response => {
      addresses.value = response.data
      selectedAddress.value = addresses.value.find(x => x.id === props.modelValue.id)
    })

    return contactsData
  } else {
    if (!query) {
      return
    }

    addressQuery.value = query

    const response = await axios.get(`/api/addresses/search?q=${query}`)
    addresses.value = response.data
  }
}

function customAddressLabel({ id, name }) {
  return `${name}`
}

onMounted(async () => {
  await Promise.all([fetchStates()])
})
</script>

<template>
  <form class="address-form">
    <div v-if="!onlyAddress" class="row">
      <div class="col-lg-12">
        <h6>{{ $t('addresses.title') }}</h6>
        <hr />
      </div>
    </div>

    <div class="row">
      <div v-if="!onlyAddress" class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.state') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedState"
              :options="states"
              :disabled="disabled"
              label="name"
              value="id"
              track-by="id"
              @update:modelValue="onSelectState"
              >
            </VueMultiselect>
            <!-- <input v-model="modelValue.state" type="text" class="form-control" :placeholder="$t('addresses.state')" name="state" required=""> -->
          </div>
        </div>
      </div><!--end col-->

      <div v-if="!onlyAddress" class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.region') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedRegion"
              :disabled="disabled || (!selectedState && !allowSelectAll)"
              :options="regions"
              label="name"
              value="id"
              track-by="id"
              @update:modelValue="onSelectRegion"
              >
            </VueMultiselect>
            <!-- <input v-model="modelValue.region" type="text" class="form-control" :placeholder="$t('addresses.region')" name="region" required=""> -->
          </div>
        </div>
      </div><!--end col-->

      <div v-if="!onlyAddress" class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.district') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedDistrict"
              :disabled="disabled || (!selectedRegion && !allowSelectAll)"
              :options="districts"
              label="name"
              value="id"
              track-by="id"
              @update:modelValue="onSelectDistrict"
              >
            </VueMultiselect>
            <!-- <input v-model="modelValue.district" type="text" class="form-control" :placeholder="$t('addresses.district')" name="district" required=""> -->
          </div>
        </div>
      </div><!--end col-->

      <div v-if="!onlyAddress" class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.subdistrict') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedSubDistrict"
              :disabled="disabled || (!selectedDistrict && !allowSelectAll)"
              :options="subDistricts"
              label="name"
              value="id"
              track-by="id"
              @update:modelValue="onSelectSubDistrict"
              >
            </VueMultiselect>
            <!-- <input v-model="modelValue.subDistrict" type="text" class="form-control" :placeholder="$t('addresses.subdistrict')" name="subdistrict" required=""> -->
          </div>
        </div>
      </div><!--end col-->
      
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.title' )}} <span v-if="!onlyAddress" class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedAddress"
              :disabled="disabled || (!selectedSubDistrict && !allowSelectAll)"
              :options="addresses"
              label="name"
              value="id"
              track-by="id"
              :internal-search="false"
              :show-labels="false"
              :option-height="104"
              :custom-label="customAddressLabel"
              @update:modelValue="onSelectAddress"
              @search-change="fetchAddresses"
              >
              <template #singleLabel="{ option }">
                <span class="option__desc d-flex flex-column">
                  <span class="option__title">{{ option.name }}</span>
                  <span v-if="onlyAddress" class="option__small text-secondary">{{ option.subDistrict }} - {{ option.district }}</span>
                  <span v-if="onlyAddress" class="option__small text-secondary">{{ option.state }} - {{ option.region }}</span>
                </span>
              </template>
              <template v-if="onlyAddress" #option="{ option, search, index }">
                <div class="option__desc d-flex flex-column">
                  <span class="option__title">{{ option.name }}</span>
                  <span v-if="onlyAddress" class="option__small text-secondary">{{ option.subDistrict }} - {{ option.district }}</span>
                  <span v-if="onlyAddress" class="option__small text-secondary">{{ option.state }} - {{ option.region }}</span>
                </div>
              </template>
              <template #noResult>
                Item not found
              </template>
            </VueMultiselect>
          </div>
        </div>
      </div><!--end col-->
      
      <div v-if="!noAddress && !disabled" class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('addresses.title_new' )}} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <textarea v-model="modelValue.name" :disabled="disabled" type="text" class="form-control" :placeholder="$t('addresses.title')" name="address" required="" />
          </div>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </form>
</template>

<style lang="scss">
.multiselect__option {
  min-height: 74px;
}
</style>