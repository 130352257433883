<script setup>
import { HubConnectionBuilder } from '@microsoft/signalr'
import Uploader from '../components/Uploader.vue'
import { onMounted } from 'vue'
import { useNotificationsStore } from '../stores/notifications'

const notificationStore = useNotificationsStore()

onMounted(() => {
  const connection = new HubConnectionBuilder()
    .withUrl("/kjpp-excel-proc-hub")
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: retryContext => {
        if (retryContext.elapsedMilliseconds < 60000) {
          return Math.random() * 10000;
        } else {
          return null;
        }
      }
    })
    .build();

  connection.serverTimeoutInMilliseconds = (1000 * (60 * 15))

  connection.on("ExcelProcessor", async function (result) {
    console.log(result)
    notificationStore.addNotification( { id: notificationStore.notifications.length+1, read: false, icon: 'file', time: 'Just now', dateCreated: (new Date()).toISOString(), title: result.message })
  })

  connection.start().then(function (resp) {
    console.log('Connected', resp)
  }).catch(function (err) {
    return console.error(err.toString())
  })

  connection.onreconnected((conId) => {
    console.log('reconnected', conId)
  })
})
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h6 class="text-muted mb-1">{{ $t('general.settings') }}</h6>
        <h5 class="mb-0">{{ $t('general.import') }}</h5>
      </div>

      <div class="mb-0 position-relative">
        <!-- <select class="form-select form-control" id="dailychart">
          <option selected="">This Month</option>
          <option value="aug">August</option>
          <option value="jul">July</option>
          <option value="jun">June</option>
        </select> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 mt-4">
        <Uploader url="/api/FileUploader" />
      </div>
    </div>
  </div>
</template>