import { defineStore } from "pinia"
import Cookie from 'js-cookie'
import axios from 'axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    count: 0,
    name: '',
    user: null
  }),
  getters: {
    doubleCount: (state) => state.count * 2,
    loggedIn: (state) => !!state.user,
    getCurrentUser: (state) => state.user
  },
  actions: {
    increment() {
      this.count++
    },
    login(user) {
      return axios.post('/login?useCookies=true', user).then(() => {
        return this.getUser()
      }).then((response) => {
        Cookie.set('user', JSON.stringify({
          email: user.email,
          role: response.role,
          name: response.name
        }))
      })
    },
    register(user) {
      return axios.post('/register', user).then(() => {
        this.setUser(user)
        Cookie.set('user', JSON.stringify({
          email: user.email,
          role: user.role,
          name: user.name
        }))
        return user
      })
    },
    async getUser() {
      return axios.get('/api/user').then((response) => {
        const { data } = response
        this.setUser(data)
        return data
    })
    },
    logout() {
      this.user = null
      Cookie.remove('user')
    },
    setUser(user) {
      this.user = user
    }
  },
})