import { createRouter, createWebHistory } from 'vue-router'

import Home from './pages/Home.vue'
import Login from './pages/auth/Login.vue'
import Register from './pages/auth/Register.vue'
import LockScreen from './pages/auth/LockScreen.vue'
import ResetPassword from './pages/auth/ResetPassword.vue'
import Logout from './pages/auth/Logout.vue'
import Assets from './pages/assets/Index.vue'
import AssetsCreate from './pages/assets/Create.vue'
import AssetsDetail from './pages/assets/Detail.vue'
import AssetsUpdate from './pages/assets/Update.vue'
import Upload from './pages/Upload.vue'
import Users from './pages/users/Index.vue'
import Contacts from './pages/contacts/Index.vue'

import { useAuthStore } from './stores/auth'
import Cookie from 'js-cookie'

const routes = [
  {
    path: '/', name: 'dashboard', component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/assets', component: Assets,
    meta: { requiresAuth: true }
  },
  {
    path: '/assets/detail/:id', component: AssetsDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/assets/update/:id', component: AssetsUpdate,
    meta: { requiresAuth: true }
  },
  {
    path: '/assets/new', component: AssetsCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/login', name: 'login', component: Login,
    meta: { requiresAuth: false, fullscreen: true }
  },
  {
    path: '/auth/register', name: 'register', component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/lock-screen', name: 'lock-screen', component: LockScreen,
    meta: { requiresAuth: false, fullscreen: true }
  },
  {
    path: '/auth/reset-password', name: 'reset-password', component: ResetPassword,
    meta: { requiresAuth: false, fullscreen: true }
  },
  {
    path: '/auth/logout', name: 'logout', component: Logout,
    meta: { requiresAuth: true }
  },
  {
    path: '/upload', name: 'upload', component: Upload,
    meta: { requiresAuth: true }
  },
  {
    path: '/users', name: 'users', component: Users,
    meta: { requiresAuth: true }
  },
  {
    path: '/contacts', name: 'contacts', component: Contacts,
    meta: { requiresAuth: true }
  },
]

const router = new createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore()
  const existingAuth = Cookie.get('user')
  if (!!existingAuth) {
    auth.setUser(JSON.parse(existingAuth))
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    if ((!to.meta.requiresAuth && !to.meta.fullscreen) && auth.loggedIn) {
      next({ path: '/' })
    } else {
      next()
    }
  }
})

export default router