<script setup>
import { onMounted, ref } from 'vue'
import CardTable from '../../components/CardTable.vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useContactStore } from '../../stores/contact'

const contactStore = useContactStore()

const { t } = useI18n({})

const items = ref([])

onMounted(async () => {
  const datas = await Promise.all([axios.get('/api/contacts'), contactStore.loadPics()])
  items.value = datas[0].data
})

const menus = ref([
  { to: '/', text: 'Paid' }
])

const columns = ref([
  {
    dataKey: 'name',
    class: 'border-bottom p-3',
    text: t('contacts.name'),
    style: '',
    tdClass: 'p-3'
  },
  {
    dataKey: 'contactType',
    class: 'border-bottom p-3',
    text: t('contacts.type'),
    style: '',
    tdClass: 'p-3'
  },
  {
    dataKey: 'phoneNumber',
    class: 'text-center border-bottom p-3',
    text: t('contacts.phone_number'),
    style: 'width: 15%',
    tdClass: 'text-center p-3'
  }
])
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">{{ $t('contacts.title') }}</h5>
      </div>

      <div class="mb-0 position-relative">
        <!-- <select class="form-select form-control" id="dailychart">
          <option selected="">This Month</option>
          <option value="aug">August</option>
          <option value="jul">July</option>
          <option value="jun">June</option>
        </select> -->
      </div>
    </div>
    <div class="col-xl-12 mt-4">
      <CardTable :title="$t('contacts.manage')" :menus="menus" :columns="columns" :items="items">
        <template #clientName="{ row }">
          <router-link to="/" class="text-primary">
            <div class="d-flex align-items-center">
              <img :src="row.clientAvatarUrl" class="avatar avatar-ex-small rounded-circle shadow" alt="">
              <span class="ms-2">{{ row.clientName }}</span>
            </div>
          </router-link>
        </template>

        <template #status="{ value }">
          <div v-if="value === 0" class="badge bg-soft-danger rounded px-3 py-1">
            Unpaid
          </div>
          <div v-else class="badge bg-soft-success rounded px-3 py-1">
            Paid
          </div>
        </template>

        <template #contactType="{ value }">
          {{ contactStore.getPics.find(pic => pic.id === value)?.name }}
        </template>

        <template #preview>
          <a href="/" class="btn btn-sm btn-primary">Preview</a>
        </template>
      </CardTable>
    </div><!--end col-->
  </div>
</template>