import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import './app.scss'
// import './style.css'
import App from './App.vue'
import routes from './routes'
import { createPinia } from 'pinia'
import 'bootstrap'
import './utils/axios-auth-interceptors'
import VueFeather from 'vue-feather'
import en from './locales/en.json'
import id from './locales/id.json'

const pinia = createPinia()

const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,
  locale: 'id',
  fallbackLocale: 'en',
  messages: {
    en: en,
    id: id
  }
})

createApp(App)
  .use(pinia)
  .use(routes)
  .use(i18n)
  .component(VueFeather.name, VueFeather)
  .mount('#app')
