import { defineStore } from "pinia"

export const useAdministrativeStore = defineStore('administrative', {
  state: () => ({
    selectedAddress: null,
  }),
  getters: {
    getSelectedAddress: (state) => state.selectedAddress
  },
  actions: {
    setSelectedAddress(obj, query) {
      this.selectedAddress = {
        ...obj,
        query
      }
    }
  },
})