<script setup>
import Sidebar from './components/Sidebar.vue';
import TopHeader from './components/TopHeader.vue';
import Footer from './components/Footer.vue';
import { useAuthStore } from './stores/auth'
import { useUIStore } from './stores/ui'

const auth = useAuthStore()
const uiStore = useUIStore()
</script>

<template>
  <div v-if="auth.loggedIn && !$route.meta.fullscreen" :class="['page-wrapper', { toggled: uiStore.sidebarVisible }]">
    <!-- sidebar-wrapper -->
    <Sidebar />
    <!-- sidebar-wrapper  -->

    <!-- Start Page Content -->
    <main class="page-content bg-light">
      <TopHeader />

      <div class="container-fluid">
        <RouterView />
      </div><!--end container-->

      <Footer />
    <!-- End -->
    </main>
    <!--End page-content" -->
  </div>
    
  <RouterView v-else />
  <!-- page-wrapper -->
</template>
