<script setup>

</script>

<template>
  <!-- Footer Start -->
  <footer class="shadow py-3">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col">
          <div class="text-sm-start text-center mx-md-2">
            <p class="mb-0 text-muted">© {{ new Date().getFullYear() }} {{ $t('general.company') }}.</p>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </footer><!--end footer-->
</template>