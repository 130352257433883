<template>
  <div>
    <div v-bind="getRootProps()" class="border border-secondary border-2 rounded p-4">
      <input v-bind="getInputProps()" name="file" />
      <p v-if="isDragActive">{{ $t('general.drop_files_Here') }}...</p>
      <p v-else>{{ $t('general.drag_n_drop_some_files_here') }}</p>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import axios from "axios";

export default {
  name: "UseDropzoneDemo",
  props: {
    url: {
      type: String,
      required: true
    },
    directUpload: {
      type: Boolean,
      default: true
    },
    fileUploadedCallback: {
      type: Function,
      default: null
    }
  },
  setup(props, { expose }) {
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        // formData.append("images[]", files[x]);
        formData.append("file", files[x]);
      }

      // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.

      axios
        .post(props.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.info(response.data);
          if (props.fileUploadedCallback) {
            props.fileUploadedCallback()
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    function onDrop(acceptFiles, rejectReasons) {
      if (props.directUpload) {
        saveFiles(acceptFiles)
      }
      expose({ acceptFiles })
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  }
};
</script>

<style lang="scss" scoped>
.main {
  .help {
    a {
      color: red;
    }
  }
}
</style>