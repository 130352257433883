<script setup>
import { onMounted, ref } from 'vue';
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'
import { useContactStore } from '../stores/contact'

const contactStore = useContactStore()

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  disabled: Boolean
})

const selectedPIC = ref(null)

function onSelectPIC(value) {
  props.modelValue.contactType = value.id
}

onMounted(async () => {
  const datas = await Promise.all([contactStore.loadPics()])
  selectedPIC.value = datas[0].find(x => x.id === props.modelValue.contactType)
})
</script>

<template>
  <form class="contact-form">
    <div class="row">
      <div class="col-lg-12">
        <h6>{{ $t('contacts.title') }}</h6>
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('contacts.type') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedPIC"
              :options="contactStore.getPics"
              label="name"
              value="id"
              track-by="id"
              :disabled="disabled"
              @select="onSelectPIC"
              >
            </VueMultiselect>
            <!-- <InputSelector :disabled="disabled" aria-label="Contacts" v-model="modelValue.kjppProperty.kjppPropertyTypeId" :options="assetTypes" :placeholder="$t('assets.choose_property_type')" label-prop="name" value-prop="id" /> -->
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('contacts.name' )}} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <input v-model="modelValue.name" type="text" class="form-control" :placeholder="$t('contacts.name')" name="name" required="">
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('contacts.phone_number') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <input v-model="modelValue.phoneNumber" type="text" class="form-control" :placeholder="$t('contacts.phone_number')" required="">
          </div>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </form>
</template>