<script setup>
import { onMounted, ref } from 'vue'
import CardTable from '../../components/CardTable.vue'
import CardGoogleMaps from '../../components/CardGoogleMaps.vue'
import DeleteConfirmation from '../../components/DeleteConfirmation.vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useUIStore } from '../../stores/ui'
import FormFilter from '../../components/FormFilter.vue'
import { useAuthStore } from '../../stores/auth'
import { useAdministrativeStore } from '../../stores/administrative'
import moment from 'moment'
import { watch } from 'vue'

const ui = useUIStore()
const { t } = useI18n({})
const administrativeStore = useAdministrativeStore()

const items = ref([])
const filter = ref({ address: { id: null, query: null, propertyTypeId: null, purposeId: null, addressId: null }, })
const total = ref(0)

function serialize(obj) {
  let str = [];
  for (var ps in obj)
    if (obj.hasOwnProperty(ps) && ps.endsWith('Id') && obj[ps] != null) {
      str.push(encodeURIComponent(ps) + "=" + encodeURIComponent(obj[ps]));
    }
  return str.join("&");
}

async function getAssets(query) {
  const combinedFilter = query ?? filter.value.address
  const queryFilter = serialize(combinedFilter)
  // console.log(filter.value, query)
  const assets = await axios.get(`/api/assets?${queryFilter}`)
  // const assets = await axios.get(`/api/assets?query=${query ?? ''}`)
  items.value = assets.data
  total.value = assets.data.length
}

onMounted(async () => {
  await getAssets()
})

async function onSearch(query) {
  await getAssets(query)
}

const menus = ref([
  { to: '/', text: 'Paid' }
])

administrativeStore.$subscribe(async (mutation, state) => {
  filter.value.address = {
    ...filter.value.address,
    addressId: state.selectedAddress.id,
    query: state.selectedAddress.query
  }
  await getAssets(filter.value.address)
})

watch(filter.value.address, async (newVal, oldVal) => {
  // await getAssets(newVal)
});

ui.$subscribe(async (mutation, state) => {
  filter.value.address.purposeId = state.selectedPurpose
  filter.value.address.propertyTypeId = state.selectedProperty
  await getAssets(filter.value.address)
})

const columns = ref([
  {
    dataKey: 'kjppProperty.kjppPropertyType',
    class: 'border-bottom p-3',
    text: t('assets.property_type'),
    style: '',
    tdClass: 'p-3'
  },
  {
    dataKey: 'kjppProperty.address.name',
    class: 'border-bottom p-3',
    text: t('assets.address'),
    style: '',
    tdClass: 'p-3'
  },
  {
    dataKey: 'areaBuilding',
    class: 'border-bottom p-3',
    text: t('assets.area_building'),
    tdClass: 'p-3'
  },
  {
    dataKey: 'areaSurface',
    class: 'text-center border-bottom p-3',
    text: t('assets.area_surface'),
    tdClass: 'text-center p-3'
  },
  {
    dataKey: 'priceLand',
    class: 'text-center border-bottom p-3',
    text: t('assets.price_land'),
    style: '',
    tdClass: 'text-center p-3'
  },
  {
    dataKey: 'kjppProperty.dateVerified',
    class: 'text-center border-bottom',
    text: t('assets.date'),
    style: 'width: 14%',
    tdClass: 'text-center p-3',
    formatter: function(value) {
      return moment(value).format('DD-MM-YYYY')
    }
  },
  {
    dataKey: 'actions',
    class: 'text-center',
    text: t('general.actions'),
    tdClass: 'text-end p-3'
  }
])

function formatCurrency(amount) {
  let IDRupiah = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })

  return IDRupiah.format(amount)
}

function onDelete(row) {
  ui.setToDelete({
    id: row.id,
    description: `${row.kjppProperty.kjppPropertyType} - ${row.kjppProperty.contactSource?.name} - ${formatCurrency(row.priceBid)}`,
    endPoints: `/api/assets/${row.kjppProperty.id}`
  })
}

const authStore = useAuthStore()
const permits = ref([])

onMounted(() => {
  if (authStore.getCurrentUser.role !== 'member') {
    permits.value.push('allow-create')
    permits.value.push('allow-update')
    permits.value.push('allow-delete')
  }
})
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">{{ $t('assets.title') }} <small>({{ total }})</small></h5>
      </div>

      <div class="mb-0 position-relative">
        <!-- <select class="form-select form-control" id="dailychart">
          <option selected="">This Month</option>
          <option value="aug">August</option>
          <option value="jul">July</option>
          <option value="jun">June</option>
        </select> -->

        <router-link v-if="permits.includes('allow-create')" class="btn btn-primary btn-sm" to="/assets/new">{{ $t('assets.new') }}</router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 mt-4">
        <CardGoogleMaps v-if="items.length > 0" :locations="items">
          <template #cluster-detail="location">
            <div class="modal-content rounded shadow border-0">
              <div class="modal-header border-bottom">
                <h5 class="modal-title" id="LoginForm-title">{{ location.kjppProperty.kjppPropertyType }}</h5>
              </div>
              <div class="modal-body">
                <div class="text-center">
                  <!-- <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                    style="height: 95px; width:95px;">
                    <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
                  </div> -->
                  <div class="mt-4">
                    <h4>{{ formatCurrency(location.priceLand) }}</h4>
                    <!-- <p class="text-muted">Create your first wishlist request...</p> -->
                    <div class="mt-4">
                      <router-link :to="`/assets/detail/${location.kjppProperty.id}`" class="btn btn-outline-primary">Detail</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CardGoogleMaps>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 mt-4">
        <FormFilter v-model="filter.address" :search-callback="onSearch" />
      </div>

      <div class="col-xl-12 mt-4">
        <CardTable :title="$t('assets.list')" :menus="menus" :columns="columns" :items="items">
          <template #clientName="{ row }">
            <router-link to="/" class="text-primary">
              <div class="d-flex align-items-center">
                <img :src="row.clientAvatarUrl" class="avatar avatar-ex-small rounded-circle shadow" alt="">
                <span class="ms-2">{{ row.clientName }}</span>
              </div>
            </router-link>
          </template>

          <template #priceLand="{ value }">
            {{ formatCurrency(value) }}
          </template>

          <template #status="{ value }">
            <div v-if="value === 0" class="badge bg-soft-danger rounded px-3 py-1">
              Unpaid
            </div>
            <div v-else class="badge bg-soft-success rounded px-3 py-1">
              Paid
            </div>
          </template>

          <template #actions="{ row }">
            <div class="btn btn-group">
              <router-link :to="`/assets/detail/${row.kjppProperty.id}`" class="btn btn-sm btn-outline-primary">{{ $t('general.details') }}</router-link>
              <router-link v-if="permits.includes('allow-update')" :to="`/assets/update/${row.kjppProperty.id}`" class="btn btn-sm btn-outline-secondary">{{ $t('general.update') }}</router-link>
              <button v-if="permits.includes('allow-delete')" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#delete-confirmation" @click="onDelete(row)">{{ $t('general.delete') }}</button>
            </div>
          </template>
        </CardTable>
      </div><!--end col-->
    </div>
  </div>

  <DeleteConfirmation @deleted="getAssets" />
</template>