import axios from 'axios'
import { useAuthStore } from '../stores/auth'
import routes from '../routes'

// Request interceptor
// axios.interceptors.request.use((config) => {
//   const authStore = useAuthStore()
//   // Modify the request config here
//   console.log(authStore.getTokenInfo)
//   return config
// })

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const authStore = useAuthStore()
    if (error.response) {
      if (error.response.status === 401 && routes.currentRoute.name !== 'login') {
        // Redirect to login page
        authStore.logout()
        routes.push('/auth/login')
      } else {
        // Show a generic error message
        // alert('An error occurred. Please try again later.')
      }
    }
    return Promise.reject(error)
  },
)