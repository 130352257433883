<script setup>
import { useUIStore } from '../stores/ui';
import { useAuthStore } from '../stores/auth';
import DropdownNotification from './DropdownNotification.vue';
import { useNotificationsStore } from '../stores/notifications';
import { onMounted } from 'vue';
import axios from 'axios'

const authStore = useAuthStore()
const uiStore = useUIStore()
const notificationsStore = useNotificationsStore()
// notificationsStore.setNotifications(
//   [
//     { id: 1, read: false, icon: 'shopping-cart', time: '15 min ago', title: 'Order Complete' },
//     { id: 2, read: true, imageUrl: '/assets/images/client/04.jpg', time: '1 hour ago', title: '<span class="fw-bold">Message</span> from Luis' },
//     { id: 3, read: false, icon: 'currency-dollar', time: '2 hour ago', title: '<span class="fw-bold">One Refund Request</span>' },
//     { id: 4, read: true, icon: 'truck-delivery', time: 'Yesterday', title: 'Delivered your Order' },
//     { id: 5, read: true, imageUrl: '/assets/images/client/15.jpg', time: '2 days ago', title: '<span class="fw-bold">Cally</span> started following you' },
//   ]
// )

onMounted(async () => {
  const notif = await axios.post('/api/user/notifications', {}).then(response => {
    const { data } = response
    const notifications = data.map(d => {
      return {
        ...d,
        icon: d.notificationType
      }
    })
    return notifications
  })
  notificationsStore.setNotifications(notif)
  return notif
})

async function onReadNotification(alert) {
  console.log(alert)
  await axios.put(`/api/user/notifications/${alert.id}/read`).then(() => {
    notificationsStore.setRead(alert.id)
  })
}
</script>

<template>
  <!-- Top Header -->
  <div class="top-header">
    <div class="header-bar d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <a href="#" class="logo-icon me-3">
          <img src="/assets/images/logo-icon.png" height="30" class="small" alt="">
          <span class="big">
            <img src="/assets/images/logo-dark.png" height="24" class="logo-light-mode" alt="">
            <img src="/assets/images/logo-light.png" height="24" class="logo-dark-mode" alt="">
          </span>
        </a>
        <a id="close-sidebar" class="btn btn-icon btn-soft-light" href="#" @click="() => uiStore.toggleSidebar()">
          <i class="ti ti-menu-2"></i>
        </a>
        <!-- <div class="search-bar p-0 d-none d-md-block ms-2">
          <div id="search" class="menu-search mb-0">
            <form role="search" method="get" id="searchform" class="searchform">
              <div>
                <input type="text" class="form-control border rounded" name="s" id="s" placeholder="Search Keywords...">
                <input type="submit" id="searchsubmit" value="Search">
              </div>
            </form>
          </div>
        </div> -->
      </div>

      <ul class="list-unstyled mb-0">
        <!-- <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight">
            <div class="btn btn-icon btn-soft-light"><i class="ti ti-settings"></i></div>
          </a>
        </li> -->

        <li class="list-inline-item mb-0 ms-1">
         <DropdownNotification
          :alerts="notificationsStore.getNotifications"
          :read-callback="onReadNotification"
          :total-unread="notificationsStore.getTotalUnread" />
        </li>

        <li class="list-inline-item mb-0 ms-1">
          <div class="dropdown dropdown-primary">
            <button type="button" class="btn btn-icon btn-soft-light dropdown-toggle p-0" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="ti ti-user"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3" style="min-width: 200px;">
              <a class="dropdown-item d-flex align-items-center text-dark pb-3" href="profile.html">
                <!-- <img src="/assets/images/client/05.jpg" class="avatar avatar-md-sm rounded-circle border shadow" alt=""> -->
                <div v-if="authStore.getCurrentUser" class="flex-1 ms-2">
                  <span class="d-block">{{ authStore.getCurrentUser.name }}</span>
                  <small class="text-muted">{{ authStore.getCurrentUser.role }}</small>
                </div>
              </a>
              <router-link class="dropdown-item text-dark" to="/"><span class="mb-0 d-inline-block me-1"><i
                    class="ti ti-home"></i></span> Dashboard</router-link>
              <!-- <router-link class="dropdown-item text-dark" to="#"><span class="mb-0 d-inline-block me-1"><i
                    class="ti ti-settings"></i></span> Profile</router-link> -->
              <div class="dropdown-divider border-top"></div>
              <!-- <router-link class="dropdown-item text-dark" to="/auth/lock-screen"><span class="mb-0 d-inline-block me-1"><i
                    class="ti ti-lock"></i></span> Lockscreen</router-link> -->
              <router-link class="dropdown-item text-dark" :to="{ name: 'logout' }"><span class="mb-0 d-inline-block me-1"><i
                    class="ti ti-logout"></i></span> {{ $t('auth.logout') }}</router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- Top Header -->
</template>