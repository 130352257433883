<script setup>
import FormUser from './FormUser.vue';
import { onMounted, ref } from 'vue';
import axios from 'axios'
import { Modal } from 'bootstrap'
import { useUsersStore } from '../stores/users';

const user = ref({
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  roleName: ''
})

const roles = ref([])
const userFormPopupRef = ref(null)
const modal = ref(null)
const errorsValidation = ref([])

const emits = defineEmits(['update'])
const usersStore = useUsersStore()

onMounted(async () => {
  const response = await axios.get('/api/users/roles')
  const { data } = response
  const availableRoles = data.map(d => {
    return {
      label: d,
      value: d
    }
  })
  roles.value = availableRoles
  usersStore.setRoles(availableRoles) 
  modal.value = new Modal(userFormPopupRef.value, {})
  userFormPopupRef.value.addEventListener('show.bs.modal', () => {
    errorsValidation.value = []

    if (usersStore.getSelectedUser) {
      user.value = {
        ...usersStore.getSelectedUser,
        roleName: usersStore.getSelectedUser.role
      }
    } else {
      user.value = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        roleName: ''
      }
    }
  })
  errorsValidation.value = []
})

async function onSaveUser(user) {
  let url
  let request
  let requestObject = {
    ...user,
    role: user.roleName
  }
  if (user?.id) {
    url = `/api/users/${user.id}`
    request = axios.put(url, requestObject)
  } else {
    url = '/api/users'
    request = axios.post(url, requestObject)
  }
  await request.then(() => {
    modal.value.hide()
    emits('update')
  })
  .catch(err => {
    if (err?.response?.data) {
      errorsValidation.value = err.response.data.map(err => err.description)
    }
  })
}

function onCancel() {
  modal.value.hide()
}

defineExpose({
  modal
})
</script>

<template>
  <!-- Login Form Start -->
  <div ref="userFormPopupRef" class="modal fade" id="user-form-popup" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-header border-bottom">
          <h5 class="modal-title" id="LoginForm-title">{{ $t('users.title') }}</h5>
        </div>

        <div class="modal-body p-0">
          <div class="container-fluid px-0">
            <div class="row align-items-center g-0">
              <div class="col-lg-12 col-md-7">
                <FormUser v-model="user" @save="onSaveUser" @cancel="onCancel" :roles="roles" :errors-validation="errorsValidation" />
              </div><!--end col-->
            </div><!--end row-->
          </div><!--end container-->
        </div>
      </div>
    </div>
  </div>
  <!-- Login Form End -->
</template>