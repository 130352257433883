<script setup>
import simplebar from 'simplebar-vue'
import SidebarItem from './SidebarItem.vue'
import { useAuthStore } from '../stores/auth'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

const { t } = useI18n({})

const menus = ref([
  {
    text: t('dashboard.title'), to: '/', icon: 'home',
    submenus: [
      { text: 'Analytics', to: '/' },
      // { text: 'Cryptocurrency', to: '/', badge: { variant: 'danger', text: 'New' } },
    ]
  },
  {
    text: t('assets.title'), to: '#', icon: 'archive',
    submenus: [
      { text: t('assets.manage'), to: '/assets' }
    ]
  },
  // {
  //   text: t('contacts.title'), to: '#', icon: 'users',
  //   submenus: [
  //     { text: t('contacts.manage'), to: '/contacts' }
  //   ]
  // },
  {
    text: t('settings.title'), to: '#', icon: 'settings',
    submenus: [
      { text: t('settings.import'), to: '/upload' }
    ],
    requiredPerm: 'allow-update'
  },
  {
    text: t('users.title'), to: '#', icon: 'user',
    submenus: [
      { text: t('users.manage'), to: '/users' }
    ],
    requiredPerm: 'allow-update'
  }
])


const authStore = useAuthStore()
const permits = ref([])
onMounted(() => {
  if (authStore.getCurrentUser.role !== 'member') {
    permits.value.push('allow-update')
    permits.value.push('allow-delete')
  }
})
</script>

<template>
  <nav id="sidebar" class="sidebar-wrapper sidebar-dark">
    <simplebar class="sidebar-content" style="height: calc(100% - 60px);">
      <div class="sidebar-brand">
        <router-link to="/">
          <img src="/assets/images/logo_trans.png" height="24" class="logo-light-mode" alt="">
          <img src="/assets/images/logo_trans.png" height="24" class="logo-dark-mode" alt="">
          <span class="sidebar-colored">
            <img src="/assets/images/logo_trans.png" height="24" alt="">
          </span>
          <span class="ms-2">{{ $t('general.company') }}</span>
        </router-link>
      </div>

      <ul class="sidebar-menu">
        <SidebarItem v-for="(menu, menuKey) in menus" :key="menuKey" v-bind="menu" :permits="permits">
        </SidebarItem>
      </ul>
      <!-- sidebar-menu  -->
    </simplebar>
    <!-- Sidebar Footer -->
    <!-- <ul class="sidebar-footer list-unstyled mb-0">
      <li class="list-inline-item mb-0">
        <a href="https://1.envato.market/landrick" target="_blank" class="btn btn-icon btn-soft-light"><i
            class="ti ti-shopping-cart"></i></a> <small class="text-muted fw-medium ms-1">Buy Now</small>
      </li>
    </ul> -->
    <!-- Sidebar Footer -->
  </nav>
</template>
