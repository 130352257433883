<script setup>
import { onMounted } from "vue";
import VueApexCharts from "vue3-apexcharts"
import { useAnalyticsStore } from "../stores/analytics";
import { ref } from "vue";
const props = defineProps(['modelValue'])
const mapRateAmount = ref({})

function formatCurrency(amount) {
  let IDRupiah = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })

  return IDRupiah.format(amount)
}

const chartOptions = {
  series: [{
    name: 'Indikasi Harga Tanah',
    type: 'column',
    data: []
  }, {
    name: 'Persentase Kenaikan',
    type: 'line',
    data: []
  }],
  chart: {
    id: "vuechart-example",
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: 'Grafik Harga Tanah'
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
    formatter: function(val, opt) {
      return mapRateAmount.value[val]
    }
  },
  labels: [],
  xaxis: {
    type: 'category'
  },
  yaxis: [
    {
      title: {
        text: 'Indikasi Harga Tanah',
      },
      labels: {
        formatter: function (value) {
          return formatCurrency(value)
        }
      }
    },
    {
      show: false,
      opposite: true,
      title: {
        text: 'Persentase Kenaikan'
      },
      labels: {
        formatter: function (value) {
          return `${mapRateAmount.value[value] ?? '0'}%`
        }
      }
    }
  ],
  tooltip: {
    x: {
      title: {
        formatter: function(v) {
          return mapRateAmount.value[v]
        }
      }
    },
    y: {
      title: {
        formatter: function(v) {
          return `${v}:`
        }
      }
    }
  }
}
const series = []

const analyticsStore = useAnalyticsStore()
const apexCharts = ref(null)

analyticsStore.$subscribe(async (item, state) => {
  const localSeries = []
  const pricesData = []

  Object.keys(state.analytics).forEach((year, index) => {
    const total = state.analytics[year].length > 0 ? state.analytics[year].reduce((x, y) => x + y) : 0
    const fixedTotal = total > 0 ? (total / state.analytics[year].length) : 0
    pricesData.push(fixedTotal)
  })

  localSeries.push({
    name: `Price`,
    type: 'column',
    data: pricesData
  })

  const ratesData = []

  Object.keys(state.rates).forEach((year, index) => {
    const rate = Math.round(state.rates[year] * 100)
    const total = state.analytics[year].length > 0 ? state.analytics[year].reduce((x, y) => x + y) : 0
    const fixedTotal = total > 0 ? (total / state.analytics[year].length) : 0
    mapRateAmount.value[fixedTotal] = rate
    ratesData.push(fixedTotal)
  })

  localSeries.push({
    name: `Rate`,
    type: 'line',
    data: ratesData
  })

  apexCharts.value.updateOptions({
    xaxis: {
      categories: Object.keys(state.analytics)
    },
    labels: Object.keys(state.analytics).map(n => n.toString())
  })
  apexCharts.value.updateSeries(localSeries, true)
});
</script>

<template>
  <div class="card shadow border-0 p-4 pb-0 rounded">
    <!-- <div class="d-flex justify-content-between">
      <h6 class="mb-0 fw-bold">Grafik Harga Tanah</h6>

      <div class="mb-0 position-relative">
        <select class="form-select form-control" id="yearchart">
          <option selected>2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
        </select>
      </div>
    </div> -->
    <VueApexCharts ref="apexCharts" width="100%" height="360" type="line" :options="chartOptions" :series="series" />
  </div>
</template>