<script setup>
import { useAuthStore } from '../../stores/auth';
import { ref } from 'vue'

const auth = useAuthStore()
const user = ref({
  name: '',
  email: '',
  password: ''
})

async function onRegister() {
  await auth.register({
    ...user.value,
    confirmPassword: user.value.password
  }).then(() => {
    window.location = '/'
  }).catch((err) => {
    const errors = err.response?.data?.errors
    if (errors) {
      console.log(errors)
    }
  })
}
</script>

<template>
  <!-- Hero Start -->
  <section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card form-signin p-4 rounded shadow">
            <form @submit.prevent="onRegister">
              <a href="index.html"><img src="/assets/images/logo-icon.png" class="avatar avatar-small mb-4 d-block mx-auto"
                  alt=""></a>
              <h5 class="mb-3 text-center">{{ $t('general.register_your_account') }}</h5>

              <div class="form-floating mb-2">
                <input v-model="user.name" type="text" class="form-control" id="floatingInput" placeholder="Harry">
                <label for="floatingInput">{{ $t('general.first_name') }}</label>
              </div>

              <div class="form-floating mb-2">
                <input v-model="user.email" type="text" class="form-control" id="floatingEmail" placeholder="name@example.com">
                <label for="floatingEmail">{{ $t('general.email_address') }}</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="user.password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                <label for="floatingPassword">{{ $t('general.password') }}</label>
              </div>

              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">{{ $t('general.i_accept') }} <a href="#" class="text-primary">{{ $t('general.terms_and_condition') }}</a></label>
              </div>

              <button class="btn btn-primary w-100" type="submit">{{ $t('general.register') }}</button>

              <div class="col-12 text-center mt-3">
                <p class="mb-0 mt-3"><small class="text-dark me-2">{{ $t('general.already_have_account') }}?</small> <router-link to="/auth/login" class="text-dark fw-bold">{{ $t('general.sign_in') }}</router-link></p>
              </div><!--end col-->

              <p class="mb-0 text-muted mt-3 text-center">© {{ new Date().getFullYear() }} {{ $t('general.company') }}.</p>
            </form>
          </div>
        </div>
      </div>
    </div> <!--end container-->
  </section><!--end section-->
  <!-- Hero End -->
</template>
