import { defineStore } from "pinia"

export const useUIStore = defineStore('ui', {
  state: () => ({
    sidebar: true,
    itemToDelete: null,
    selectedProperty: null,
    selectedPurpose: null
  }),
  getters: {
    sidebarVisible: (state) => state.sidebar,
    getToDelete: (state) => state.itemToDelete
  },
  actions: {
    setSidebar(visible) {
      this.sidebar = visible
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar
    },
    setToDelete(item) {
      this.itemToDelete = item
    },
    setSelectedProperty(item) {
      this.selectedProperty = item
    },
    setSelectedPurpose(item) {
      this.selectedPurpose = item
    }
  },
})