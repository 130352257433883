<script setup>
import { onMounted, ref } from 'vue'
import CardTable from '../../components/CardTable.vue'
import axios from 'axios'
import { useUsersStore } from '../../stores/users';
import ModalCreateUserForm from '../../components/ModalCreateUserForm.vue'
import DeleteConfirmation from '../../components/DeleteConfirmation.vue'
import { useI18n } from 'vue-i18n'
import { useUIStore } from '../../stores/ui';

const usersStore = useUsersStore()
const ui = useUIStore()
const { t } = useI18n({})

async function loadUsers() {
  const response = await axios.get('/api/users')
  const { data } = response
  usersStore.setUsers(data)
}

onMounted(async () => {
  await loadUsers()
})

const menus = ref([
  // { to: '/', text: 'Paid' }
])
const modalUserForm = ref(null)

const columns = ref([
  {
    dataKey: 'name',
    class: 'border-bottom p-3',
    text: t('users.name'),
    style: 'width: 25%',
    tdClass: 'p-3'
  },
  {
    dataKey: 'email',
    class: 'border-bottom p-3',
    text: t('users.email'),
    tdClass: 'p-3'
  },
  {
    dataKey: 'role',
    class: 'border-bottom p-3',
    text: t('users.role'),
    tdClass: 'p-3'
  },
  {
    dataKey: 'actions',
    text: t('general.actions'),
    style: 'width: 10%',
  }
])

async function onNewUser() {
  usersStore.setSelectedUser(null)
  modalUserForm.value.modal.show()
}

function onUpdateUser(user) {
  usersStore.setSelectedUser(user)
  modalUserForm.value.modal.show()
}

function onDelete(user) {
  ui.setToDelete({
    id: user.id,
    description: `${user.name} - ${user.email}`,
    endPoints: `/api/users/${user.id}`
  })
}
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">{{ $t('users.title') }}</h5>
      </div>

      <div class="mb-0 position-relative">
        <!-- <select class="form-select form-control" id="dailychart">
          <option selected="">This Month</option>
          <option value="aug">August</option>
          <option value="jul">July</option>
          <option value="jun">June</option>
        </select> -->
        <button class="btn btn-primary" @click="onNewUser">{{ $t('users.new_user') }}</button>
      </div>
    </div>
    <div class="col-xl-12 mt-4">
      <CardTable :title="$t('users.manage')" :menus="menus" :columns="columns" :items="usersStore.getUsers">
        <template #clientName="{ row }">
          <router-link to="/" class="text-primary">
            <div class="d-flex align-items-center">
              <img :src="row.clientAvatarUrl" class="avatar avatar-ex-small rounded-circle shadow" alt="">
              <span class="ms-2">{{ row.clientName }}</span>
            </div>
          </router-link>
        </template>

        <template #actions="{ row }">
          <div class="btn-group">
            <button class="btn btn-sm btn-outline-primary" @click="onUpdateUser(row)">{{ $t('general.update') }}</button>
            <button class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#delete-confirmation" @click="onDelete(row)">{{ $t('general.delete') }}</button>
          </div>
        </template>

        <template #status="{ value }">
          <div v-if="value === 0" class="badge bg-soft-danger rounded px-3 py-1">
            Unpaid
          </div>
          <div v-else class="badge bg-soft-success rounded px-3 py-1">
            Paid
          </div>
        </template>
      </CardTable>
    </div><!--end col-->
  </div>

  <ModalCreateUserForm ref="modalUserForm" @update="loadUsers" />
  <DeleteConfirmation @deleted="loadUsers" />
</template>