import { defineStore } from "pinia"
import axios from 'axios'

export const useContactStore = defineStore('contact', {
  state: () => ({
    pics: [],
    contacts: []
  }),
  getters: {
    getPics: (state) => state.pics,
    getContacts: (state) => state.contacts
  },
  actions: {
    async loadPics() {
      if (this.pics.length > 0) {
        return this.pics
      }

      const that = this
      return axios.get('/api/assets/pics').then(response => {
        const { data } = response
        const fixedData = Object.keys(data).map(x => {
          const res = {
            id: data[x],
            name: x
          }
          if (!that.pics.some(p => p.id === data[x])) {
            that.pics.push(res)
          }
          return res
        })
        return fixedData
      })
    },
    setToDelete(item) {
      this.itemToDelete = item
    }
  },
})