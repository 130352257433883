<script setup>
import { ref } from 'vue';
import { useAuthStore } from '../../stores/auth';
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';

const auth = useAuthStore()
const errorsValidation = ref([])
const { t } = useI18n({})

const user = ref({
  email: '',
  password: ''
})

function onLogin() {
  auth.login(user.value).then(() => {
    window.location = '/'
  }).catch(err => {
    if (err?.response?.data) {
      if (err.response.data?.status === 401) {
        errorsValidation.value = [ t('auth.login_fail') ]
      } else {
        errorsValidation.value = [ err.response.data?.detail ]
      }
    }
  })
}

watch(user.value, (newVal, oldVal) => {
  errorsValidation.value = []
})

</script>

<template>
  <!-- Hero Start -->
  <section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card form-signin p-4 rounded shadow">
            <form @submit.prevent="onLogin">
              <router-link to="/">
                <img src="/assets/images/logo_trans.png" class="avatar avatar-small mb-4 d-block mx-auto"
                  alt="">
              </router-link>
              <h5 class="mb-3 text-center">{{ $t('general.please_sign_in') }}</h5>

              <div v-if="errorsValidation.length > 0">
                <div class="alert alert-danger" role="alert" v-html="errorsValidation.join('<br />')">
                </div>
              </div>

              <div class="form-floating mb-2">
                <input v-model="user.email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com" required>
                <label for="floatingInput">{{ $t('general.email_address') }}</label>
              </div>
              <div class="form-floating mb-3">
                <input v-model="user.password" type="password" class="form-control" id="floatingPassword" placeholder="Password" required>
                <label for="floatingPassword">{{ $t('general.password') }}</label>
              </div>

              <!-- <div class="d-flex justify-content-between">
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">{{ $t('general.remember_me') }}</label>
                  </div>
                </div>
                <p class="forgot-pass mb-0"><router-link to="/auth/reset-password" class="text-dark small fw-bold">{{ $t('general.forgot_password') }}?</router-link></p>
              </div> -->

              <button class="btn btn-primary w-100" type="submit">{{ $t('auth.login') }}</button>

              <!-- <div class="col-12 text-center mt-3">
                <p class="mb-0 mt-3">
                  <small class="text-dark me-2">{{ $t('general.do_not_have_account') }}?</small>
                  <router-link to="/auth/register" class="text-dark fw-bold">{{ $t('general.register') }}</router-link>
                </p>
              </div> -->
              <!--end col-->

              <p class="mb-0 text-muted mt-3 text-center">© {{ new Date().getFullYear() }} {{ $t('general.company') }}.</p>
            </form>
          </div>
        </div>
      </div>
    </div> <!--end container-->
  </section><!--end section-->
  <!-- Hero End -->
</template>