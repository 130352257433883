<script setup>
import { GoogleMap, Marker, MarkerCluster, InfoWindow } from "vue3-google-map";
const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

defineProps({
  locations: {
    type: Array,
    default: () => []
  },
  center: {
    type: Object,
    default: () => {
      return {
        lat: -6.916181, lng: 107.678882
      }
    }
  },
  zoom: {
    type: Number,
    default: 9
  }
})
</script>

<template>
  <div class="card border-0">
    <div class="d-flex justify-content-between p-4 shadow rounded-top">
      <h6 class="fw-bold mb-0">{{ $t('general.locations') }}</h6>

      <ul class="list-unstyled mb-0">
        <li class="dropdown dropdown-primary list-inline-item">
          <button type="button" class="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
              class="ti ti-dots-vertical"></i></button>
          <div class="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3">
            <a class="dropdown-item text-dark" href="#"> Paid</a>
            <a class="dropdown-item text-dark" href="#"> Unpaid</a>
          </div>
        </li>
      </ul>
    </div>

    <GoogleMap :api-key="apiKey" class="shadow rounded-bottom"
      style="width: 100%; height: 500px" :center="center" :zoom="zoom">
      <Marker :options="{ position: center }">
        <InfoWindow>
          <div id="content">
            <div id="siteNotice"></div>
            <h1 id="firstHeading" class="firstHeading">Uluru</h1>
            <div id="bodyContent">
              <p>
                <b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large sandstone rock formation in the southern
                part of the Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) south west of the
                nearest large town, Alice Springs; 450&#160;km (280&#160;mi) by road. Kata Tjuta and Uluru are the two
                major features of the Uluru - Kata Tjuta National Park. Uluru is sacred to the Pitjantjatjara and
                Yankunytjatjara, the Aboriginal people of the area. It has many springs, waterholes, rock caves and
                ancient paintings. Uluru is listed as a World Heritage Site.
              </p>
              <p>
                Attribution: Uluru,
                <router-link to="/assets/detail/1">https://en.wikipedia.org/w/index.php?title=Uluru</router-link>
                (last visited June 22, 2009).
              </p>
            </div>
          </div>
        </InfoWindow>
      </Marker>

      <MarkerCluster>
        <Marker v-for="(location, i) in locations" :options="{ position: { lat: location.locationLatitude, lng: location.locationLongitude } }" :key="i">
          <InfoWindow>
            <slot name="cluster-detail" v-bind="location">
              <div id="content">
                <div id="siteNotice"></div>
                <h1 id="firstHeading" class="firstHeading">Uluru</h1>
                <div id="bodyContent">
                  <p>
                    <b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large sandstone rock formation in the southern
                    part of the Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) south west of the
                    nearest large town, Alice Springs; 450&#160;km (280&#160;mi) by road. Kata Tjuta and Uluru are the two
                    major features of the Uluru - Kata Tjuta National Park. Uluru is sacred to the Pitjantjatjara and
                    Yankunytjatjara, the Aboriginal people of the area. It has many springs, waterholes, rock caves and
                    ancient paintings. Uluru is listed as a World Heritage Site.
                  </p>
                  <p>
                    Attribution: Uluru,
                    <router-link :to="`/assets/detail/${i}`">https://en.wikipedia.org/w/index.php?title=Uluru</router-link>
                    (last visited June 22, 2009).
                  </p>
                </div>
              </div>
            </slot>
          </InfoWindow>
        </Marker>
      </MarkerCluster>
    </GoogleMap>
  </div>
</template>