<script setup>
import { onMounted, ref } from 'vue';
import FormAsset from '../../components/FormAsset.vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '../../stores/auth';
const route = useRoute()
const router = useRouter()

const detail = ref(null)

const authStore = useAuthStore()
const permits = ref([])

onMounted(async () => {
  const response = await axios.get(`/api/assets/${route.params.id}`)
  
  detail.value = response.data

  if (authStore.getCurrentUser.role !== 'member') {
    permits.value.push('allow-update')
    permits.value.push('allow-delete')
  }
})

async function onUpdateAsset(model) {
  await axios.put(`/api/assets/${model.id}`, model).then(() => {
    router.go(-1)
  })
}
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 class="mb-0">Assets Detail</h5>
      </div>
    </div>

    <FormAsset v-if="detail" v-model="detail" disabled @update="onUpdateAsset" :permits="permits" />
  </div>
</template>