<script setup>
import CardChart from '../components/CardChart.vue'
import { ref } from 'vue';
import FormFilter from '../components/FormFilter.vue';
import { watch } from 'vue';
import axios from 'axios'
import { useAnalyticsStore } from '../stores/analytics';
import { onMounted } from 'vue';
import { useAuthStore } from '../stores/auth';
// import CardGraph from '../components/CardGraph.vue'
// import CardDonut from '../components/CardGraph.vue'
const filter = ref({ address: { addressId: null, propertyTypeId: null, purposeId: null } })
const years = ref([])
const yearsData = ref({})
const authStore = useAuthStore()

const analyticsStore = useAnalyticsStore()

function serialize(obj) {
  let str = [];
  for (var ps in obj)
    if (obj.hasOwnProperty(ps) && ps.endsWith('Id') && obj[ps] != null) {
      str.push(encodeURIComponent(ps) + "=" + encodeURIComponent(obj[ps]));
    }
  return str.join("&");
}

async function fetchAssets(filter) {
  const query = serialize(filter)
  const response = await axios.get(`/api/assets/analytics?${query}`)
  const { data } = response
  years.value = Object.keys(data.resultsYearly)
  yearsData.value = {
    years: data.results
  }
  analyticsStore.setAnalytics(data.resultsYearly)
  analyticsStore.setRates(data.resultsYearlyRate)
}

watch(filter.value.address, async (newVal, oldVal) => {
  await fetchAssets(newVal)
})

onMounted(async() => {
  await fetchAssets({})
})
</script>

<template>
  <div class="layout-specing">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h6 class="text-muted mb-1">Welcome back, {{ authStore.getCurrentUser?.name }}!</h6>
        <h5 class="mb-0">Dashboard</h5>
      </div>

      <div class="mb-0 position-relative">
        <!-- <select class="form-select form-control" id="dailychart">
          <option selected="">This Month</option>
          <option value="aug">August</option>
          <option value="jul">July</option>
          <option value="jun">June</option>
        </select> -->
      </div>
    </div>

    <!-- <Statistics /> -->

    <div class="row">
      <div class="col-xl-6 col-lg-5 mt-4 rounded">
        <FormFilter v-model="filter.address" hide-search />
      </div><!--end col-->

      <div class="col-xl-6 col-lg-7 mt-4">
        <CardChart v-model="yearsData" />
      </div><!--end col-->
    </div><!--end row-->
  </div>
</template>