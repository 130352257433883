<script setup>
import simplebar from 'simplebar-vue'
import { computed } from 'vue';
const props = defineProps({
  title: '',
  menus: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: []
  },
  items: {
    type: Array,
    default: []
  }
})

const computedColumns = computed(() => {
  return props.columns
})

function getPropByString(obj, propString, formatter) {
  if (!propString || !obj)
    return obj;

  var prop, props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  
  return formatter ? formatter(obj[props[i]]) : (obj ? obj[props[i]] : '');
}
</script>

<template>
  <div class="card border-0">
    <div class="d-flex justify-content-between p-4 shadow rounded-top">
      <h6 class="fw-bold mb-0">{{ title }}</h6>

      <ul v-if="menus.length > 0" class="list-unstyled mb-0">
        <li class="dropdown dropdown-primary list-inline-item">
          <button type="button" class="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
              class="ti ti-dots-vertical"></i></button>
          <div class="dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3">
            <router-link v-for="(menu, menuKey) in menus" :key="menuKey" class="dropdown-item text-dark" :to="menu.to"> {{ menu.text }}</router-link>
          </div>
        </li>
      </ul>
    </div>
    <simplebar class="table-responsive shadow rounded-bottom" style="height: 545px;">
      <table class="table table-center bg-white mb-0">
        <thead>
          <tr>
            <th
              v-for="(column, columnKey) in columns"
              :key="columnKey"
              :class="column.class"
              :style="column.style"
            >
              {{ column.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Start -->
          <tr v-for="(item, itemKey) in items" :key="itemKey">
            <td
              v-for="(column, columnKey) in columns"
              :key="columnKey"
              :class="column.tdClass"
            >
              <slot
                :name="column.dataKey"
                v-bind="{ value: item[column.dataKey], row: item }"
              >
                {{ getPropByString(item, column.dataKey, column.formatter) }}
              </slot>
            </td>
          </tr>
          <!-- End -->
        </tbody>
      </table>
    </simplebar>
  </div>
</template>