import { defineStore } from "pinia"

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    selectedUser: null,
    roles: []
  }),
  getters: {
    getUsers: (state) => state.users,
    getSelectedUser: (state) => state.selectedUser,
    getRoles: (state) => state.roles
  },
  actions: {
    setUsers(obj) {
      this.users = obj
    },
    setSelectedUser(obj) {
      this.selectedUser = obj
    },
    setRoles(obj) {
      this.roles = obj
    }
  },
})