<script setup>
import { ref } from 'vue';
import FormAddress from './FormAddress.vue';
import { onMounted, watch } from 'vue';
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'
import { useUIStore } from '../stores/ui';

const address = ref({})
const selectedPropertyType = ref(null)
const selectedPurpose = ref(null)
const purposes = ref([])
const propertyTypes = ref([])
const uiStore = useUIStore()

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  searchCallback: Function,
  hideSearch: Boolean
})

async function onSearch() {
  if (props.searchCallback) {
    props.searchCallback()
  }
}

function onSelectPropertyType(value) {
  props.modelValue.propertyTypeId = value?.id ?? null
  uiStore.setSelectedProperty(value?.id ?? null)
}

function onSelectPurpose(value) {
  props.modelValue.purposeId = value?.id ?? null
  uiStore.setSelectedPurpose(value?.id ?? null)
}

function fetchTypes() {
  const assetTypesData = axios.get('/api/assets/types').then(response => {
    propertyTypes.value = response.data
  })
  return assetTypesData
}

function fetchPropertyPurposes() {
  const purposesData = axios.get('/api/assets/purposes').then(response => {
    purposes.value = response.data
  })
  return purposesData
}

onMounted(async () => {
  address.value = props.modelValue
  const lsit = await Promise.all([fetchTypes(), fetchPropertyPurposes()])
})
</script>

<template>
  <div class="card shadow border-0 rounded">
    <div class="d-flex justify-content-between p-4 shadow rounded-top">
      <h6 class="fw-bold mb-0">Filter</h6>
    </div>

    <div class="p-4 rounded-bottom">
      <form @submit.prevent="onSearch">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.property_type') }}</label>
              <div class="form-icon position-relative">
                <VueMultiselect
                  v-model="selectedPropertyType"
                  :options="propertyTypes"
                  label="name"
                  value="id"
                  track-by="id"
                  @update:modelValue="onSelectPropertyType"
                  >
                </VueMultiselect>
              </div>
            </div>
          </div><!--end col-->

          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">{{ $t('assets.property_purpose') }}</label>
              <div class="form-icon position-relative">
                <VueMultiselect
                  v-model="selectedPurpose"
                  :options="purposes"
                  label="name"
                  value="id"
                  track-by="id"
                  @update:modelValue="onSelectPurpose"
                  >
                </VueMultiselect>
              </div>
            </div>
          </div><!--end col-->
        </div>

        <FormAddress v-model="address" no-address allow-select-all only-address />

        <button v-if="!hideSearch" class="btn btn-primary">Search</button>
      </form>
    </div>
  </div>
</template>