<script setup>
import { useAuthStore } from '../../stores/auth'
import { onMounted } from 'vue';

const auth = useAuthStore()

onMounted(() => {
  auth.logout()
  setTimeout(() => {
    window.location = '/'
  }, 1000)
})
</script>

<template>
  <div>
    Logging out...
  </div>
</template>