<script setup>
import { onMounted } from 'vue';
import { ref, watch } from 'vue';
import VueMultiselect from 'vue-multiselect'
import { useUsersStore } from '../stores/users';

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  roles: {
    type: Array,
    default: () => []
  },
  errorsValidation: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['save', 'cancel'])

const selectedRole = ref(null)
const usersStore = useUsersStore()

function onSelectRole(value) {
  if (value) {
    props.modelValue.roleName = value.value
  } else {
    props.modelValue.roleName = ''
  }
}

usersStore.$subscribe((item, state) => {
  if (props.modelValue.roleName) {
    const existingRole = state.roles.find(r => r.value === props.modelValue.roleName)
    if (existingRole) {
      selectedRole.value = existingRole
    }
  } else {
    selectedRole.value = null
  }
})

function onCancel() {
  emits('cancel')
}
</script>

<template>
  <form class="login-form p-4" @submit.prevent="$emit('save', modelValue)" autocomplete="off">
    <div class="row">
      <div v-if="errorsValidation.length > 0" class="col-lg-12">
        <div class="alert alert-danger" role="alert" v-html="errorsValidation.join('<br />')">
        </div>
      </div>

      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.name') }} <span class="text-danger">*</span></label>

          <div class="form-icon position-relative">
            <vue-feather class="icons" type="user" size="16"></vue-feather>
            <input v-model="modelValue.name" type="text" class="form-control ps-5" :placeholder="$t('users.name')" name="name" required />
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.email') }} <span class="text-danger">*</span></label>

          <div class="form-icon position-relative">
            <vue-feather class="icons" type="mail" size="16"></vue-feather>
            <input v-model="modelValue.email" type="email" class="form-control ps-5" :placeholder="$t('users.email')" id="email" name="email" required />
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.role') }} <span class="text-danger">*</span></label>

          <div class="form-icon position-relative">
            <VueMultiselect
              v-model="selectedRole"
              :options="usersStore.getRoles"
              label="label"
              value="value"
              track-by="value"
              @update:modelValue="onSelectRole"
              >
            </VueMultiselect>
          </div>
        </div>
      </div><!--end col-->

      <div v-if="!!modelValue.id" class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.old_password') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <vue-feather class="icons" type="key" size="16"></vue-feather>
            <input v-model="modelValue.oldPassword" type="password" class="form-control ps-5" :placeholder="$t('users.old_password')" id="old-password" name="oldPassword" />
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.password') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <vue-feather class="icons" type="key" size="16"></vue-feather>
            <input v-model="modelValue.password" type="password" class="form-control ps-5" :placeholder="$t('users.password')" id="new-password" name="newPassword" />
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-6">
        <div class="mb-3">
          <label class="form-label">{{ $t('users.confirm_password') }} <span class="text-danger">*</span></label>
          <div class="form-icon position-relative">
            <vue-feather class="icons" type="key" size="16"></vue-feather>
            <input v-model="modelValue.confirmPassword" type="password" class="form-control ps-5" :placeholder="$t('users.confirm_password')" id="confirm-password" name="confirmPassword" />
          </div>
        </div>
      </div><!--end col-->

      <div class="col-lg-12 mb-0">
        <div class="d-flex justify-content-end align-items-center">
          <button class="btn btn-outline-secondary me-2" type="button" @click="onCancel">{{ $t('general.cancel') }}</button>
          <button class="btn btn-primary" type="submit">{{ $t('general.save') }}</button>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </form>
</template>