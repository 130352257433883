<script setup>
import { useUIStore } from '../stores/ui';
import axios from 'axios'
const emit = defineEmits(['deleted'])

const ui = useUIStore()

async function onDeleteConfirmed() {
  if (ui.getToDelete.endPoints) {
    await axios.delete(ui.getToDelete.endPoints).then((response) => {
      ui.setToDelete(null)
      emit('deleted')
    }).catch(err => {
      ui.setToDelete(null)
      emit('deleted')
    })
  }
}
</script>

<template>
  <!-- Wishlist Popup Start -->
  <div class="modal fade" id="delete-confirmation" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-body py-5">
          <div class="text-center">
            <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
              style="height: 95px; width:95px;">
              <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
            </div>
            <div v-if="ui.getToDelete" class="mt-4">
              <h4>{{ $t('general.delete_confirmation') }}?</h4>
              <p class="text-muted">{{ ui.getToDelete.description }}</p>
              <div class="mt-4 btn btn-group">
                <button data-bs-toggle="modal" data-bs-target="#delete-confirmation" class="btn btn-outline-secondary">{{ $t('general.no') }}</button>
                <button data-bs-toggle="modal" data-bs-target="#delete-confirmation" class="btn btn-outline-danger" @click="onDeleteConfirmed">{{ $t('general.yes') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Wishlist Popup End -->
</template>